const state = {
	admin_id: localStorage.getItem("admin_id") || '',
	admin_token: localStorage.getItem("admin_token") || '',
	admin_name: localStorage.getItem("admin_name") || '',
};


const getters = {
	isAdminLoggedIn(state) {
		if (state.admin_token == "" || state.admin_token == null || state.admin_token == undefined) {
			return false;
		} else {
			return true;
		}
	}
};

const mutations = {
	setAdminData(state, adminData) {
		state.admin_id = adminData.id;
		state.admin_token = adminData.token;
		state.admin_name = adminData.name;

		/* set local storage */
		localStorage.setItem('admin_id', adminData.id);
		localStorage.setItem('admin_token', adminData.token);
		localStorage.setItem('admin_name', adminData.name);
	},
	unsetAdminData(state) {
		state.id = '';
		state.admin_token = '';
		state.admin_name = '';

		/* set local storage */
		localStorage.setItem('admin_id', '');
		localStorage.setItem('admin_token', '');
		localStorage.setItem('admin_name', '');
	},

};

const actions = {
	loginAdmin(context, creds) {
		return new Promise((resolve, reject) => {
			/* make data */
			var data = new FormData();
			data.append("username", creds.username);
			data.append("password", creds.password);

			/* make call to server */
			this._vm.$http.post("admin/admins/loginAdmin", data).then((result) => {
				if (result.data.status == "ok") {
					context.commit('setAdminData', {
						id: result.data.data.admin.id,
						token: result.data.data.admin.token,
						name: result.data.data.admin.name,
					});
					resolve(result.data.data);
				} else {
					reject(result.data.error);
				}
			}).catch(function (error) {
				reject(error);
			});
		})
	},
	logoutAdmin(context) {
		context.commit('unsetAdminData');
	},
	updateAdminData(context) {
		return new Promise((resolve, reject) => {
			/* make data */
			var data = new FormData();
			data.append("token", context.state.admin_token);

			/* make call to server */
			this._vm.$http.post("admin/admins/authToken", data).then((result) => {
				if (result.data.status == "ok") {
					context.commit('setAdminData', {
						id: result.data.data.admin.id,
						token: result.data.data.admin.token,
						name: result.data.data.admin.name,
					});
					resolve(result.data.data);
				} else {
					reject(result.data.error);
				}
			}).catch(function (error) {
				reject(error);
			});
		})
	}
};

export default {
	state,
	getters,
	mutations,
	actions
};
