<template>
	<div>
		<v-col cols="12">
			<ChangePassword/>	
			<Options class="mt-5"/>	
		</v-col>
	</div>
</template>

<script>
import ChangePassword from "./ChangePassword"
import Options from "./Options"

export default {
	name: "Settings",
	components: {
		ChangePassword,
		Options,
	},
	data: () => ({
		//
	}),
};
</script>

<style>
</style>