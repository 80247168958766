<template>
	<v-app>
		<router-view></router-view>
	</v-app>
</template>

<script>

export default {
	name: 'App',
	data: () => ({
		//
	}),
};
</script>

<style lang="scss">
/* fonts */
@import "./assets/fonts/loader.css";

.v-application {
	font-family: "Inter", sans-serif !important;
	[class*="text-"] {
		font-family: "Inter", sans-serif !important;
	}
}

.swal2-container {
	font-family: "Inter";
}

.swal2-shown {
	padding-right: 0 !important;
}

.admin-page-content {
	width: 100%;
	padding: 12px 0;
}
</style>
