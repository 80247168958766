<template>
	<div>
		<v-card elevation="2" :loading="cardLoading">
			<!-- top -->
			<v-card-title class="primary white--text">
				Options
			</v-card-title>

			<!-- main -->
			<v-simple-table class="text-center text-no-wrap">
				<template v-slot:default>
					<thead>
						<tr>
							<th class="text-center">Item</th>
							<th class="text-center">Value</th>
							<th class="text-center"></th>
						</tr>
					</thead>

					<tbody>
						<tr v-for="(item, index) in settings" :key="item.id">
							<td>{{item.title}}</td>
							<td class="py-3">
								<v-textarea label="Value" rows="2" solo hide-details flat v-model="item.value" style="min-width: 150px;"></v-textarea>
							</td>
							<td>
								<v-btn type="submit" color="primary" depressed :loading="settingBtnsLoading.includes(index)" @click="updateSetting(index , item.title , item.value)">Update</v-btn>
							</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</v-card>
	</div>
</template>

<script>
export default {
	name: "Options",
	data: () => ({
		loadingSettings: true,
		settingBtnsLoading: [],
		settings: []
	}),
	computed: {
		cardLoading() {
			return this.loadingSettings ? 'blue lighten-3' : false;
		}
	},
	methods: {
		getSettings() {
			/* call the api */
			this.loadingSettings = true;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.Admin.admin_token);

			/* make call to server */
			this.$http.post("admin/settings/getAllSettings", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.settings = result.data.data.settings;
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							confirmButtonText: "Okay",
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						confirmButtonText: "Okay",
					});
				})
				.finally(() => {
					this.loadingSettings = false;
				})
		},
		updateSetting(index, title, value) {
			/* set laoding */
			this.settingBtnsLoading.push(index);

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.Admin.admin_token);
			data.append("key", title);
			data.append("value", value);

			/* make call to server */
			this.$http.post("admin/settings/updateSetting", data)
				.then((result) => {
					if (result.data.status == "ok") {
						for (var i = 0; i < result.data.data.settings.length; i++) {
							if (result.data.data.settings[i].title == title) {
								this.settings[index].value = result.data.data.settings[i].value;
							}
						}
						this.$swal({
							icon: "success",
							text: 'Setting updated!',
							confirmButtonText: "Okay",
						});
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							confirmButtonText: "Okay",
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						confirmButtonText: "Okay",
					});
				})
				.finally(() => {
					this.settingBtnsLoading.splice(this.settingBtnsLoading.indexOf(index), 1);
				})
		}
	},
	created() {
		this.getSettings();
	}
};
</script>

<style>
</style>