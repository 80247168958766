<template>
	<div class="mr-3" style="flex: 1;">
		<div class="search-input ml-auto">
			<v-form @submit.prevent="($emit('submit'))">
				<input type="text" :placeholder="placeholder" v-model="inputVal">
				<v-btn class="input-icon" depressed icon small type="submit">
					<v-icon>mdi-magnify</v-icon>
				</v-btn>
			</v-form>
		</div>
	</div>
</template>

<script>
export default {
	name: "SearchInput",
	props: {
		placeholder: {
			type: String,
			default: "Search"
		},
		value: {
			default: ''
		}
	},
	computed: {
		inputVal: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit('input', val);
			}
		}
	}
};
</script>

<style>
.search-input {
	background: #ffffffaa;
	border-radius: 5px;
	position: relative;
	max-width: 200px;
}

.search-input input {
	font-size: 15px;
	font-weight: 400;
	width: 100%;
	padding: 0 30px 0 10px;
	caret-color: auto;
	outline-style: none !important;
}

.search-input .input-icon {
	position: absolute !important;
	right: 4px;
	top: 3px;
}
</style>