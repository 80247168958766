<template>
	<div style="height: 100%;">
        <Header />

		<v-main class="blue lighten-5" style="height: 100%;">
			<v-container class="px-4 py-0" fluid>
				<div class="admin-page-content">
					<transition name="fade">
						<router-view></router-view>
					</transition>
				</div>
			</v-container>
		</v-main>
    </div>
</template>

<script>
import Header from "../components/Header"

export default {
    name: 'Panel',
	data: () => ({
		//
	}),
	components: {
		Header
	},
    created() {
        /* check if admin is login */
        if (!this.$store.getters.isAdminLoggedIn) {
            this.$router.push("/login");
        }
    },
}
</script>

<style>
</style>